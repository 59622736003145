<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
<div class="BGWhite" style="border-bottom: 20px solid #ffffff;">
	<div class="Wrap">
		
		<div class="BuyTitle">
			填写&核对订单信息
		</div>
		
		<div class="Address">
			
			<div class="Title">
				<h3>收货人信息</h3>
				<div>
					<span @click="NewAddressDialog = true">+ 使用新地址</span>
					<span v-if="!IsShowAllList" @click="IsShowAllList = true">展开全部</span>
					<span v-if="IsShowAllList"  @click="IsShowAllList = false">收起</span>
				</div>
			</div>
			
			<div :class=" IsShowAllList ? 'List AllList':'List'">
				
				<!--只显示被选中的地址-->
				<template v-for="item in AddressList">
				<div class="One" :key="item.Id" @click="SelAddr(item)" v-if="item.Id == AddressId">
					<li>{{item.Addressee}} {{$StrStar(item.Mobile)}}</li>
					<p>{{item.Province}}{{item.City}}{{item.Region}}{{item.Town ? item.Town:''}}{{item.Detail}}
</p><span >
						<i class="el-icon-check"></i>
					</span>
				</div>
				</template>
				<!--只显示fei选中的地址-->
				<template v-for="item in AddressList">
				<div class="One" :key="item.Id" @click="SelAddr(item)" v-if="item.Id != AddressId">
					<li>{{item.Addressee}} {{$StrStar(item.Mobile)}}</li>
					<p>{{item.Province}}{{item.City}}{{item.Region}}{{item.Town ? item.Town:''}}{{item.Detail}}

</p>
				</div>
				</template>
								
				
				<template v-if="AddressList.length < 4">
					<div class="One" @click="NewAddressDialog = true">
						<ul><i class="el-icon-circle-plus"></i>创建地址</ul>
					</div>
					
					<div class="One Empty" v-for="k in (3-AddressList.length%3)" :key="k">
					</div>
					
				</template>
				
			</div>
			
		</div>
		
		
		<div style="width: 100%;margin-bottom: 10px;" v-for="(shopCart,shopCartI) in CartList" :key="shopCartI">
			<div class="GoodsList">
				<div class="Hi Title" style="color: rgba(0,0,0,0.4);font-size:0.9rem">
					<span class="C2"></span>
					<span class="C3">
						<div style="display: flex;justify-content: space-between;">
							<label>{{shopCart.Name}}</label>
							<div>
								<el-select @change="CouponMoneyCount" v-model="shopCart.CouponId" size="mini" placeholder="店铺优惠券">
									<el-option :label="'不在当前店使用优惠券'" :value="''"></el-option>
									<template v-for="coupon in CouponList">
										
										<el-option v-if="coupon.ShopId == shopCart.Id || coupon.ShopId == 'all'" :key="coupon.Id" :label="'满'+coupon.MoneyStart+'减'+coupon.CuponMoney" :value="coupon.Id"></el-option>
									</template>
								</el-select>
							</div>
						</div>
					</span>
					<span class="C5">单价</span>
					<span class="C6">数量</span>
					<span class="C8">小计</span>
				</div>
			</div>
		
				<div class="GoodsList">
					<div class="Hi Cart" v-for="item in shopCart.GoodsList" :key="item.Id">
						<span class="C2"><img :src="item.GoodsThumb"  @click="$Jump('/goods/detail/'+item.GoodsId)" /></span>
						<span class="C3">
							<div>
								<p>
									<i v-if="item.IsPt === 1">[拼团]</i>
								{{item.GoodsName}}</p>
								<em v-if="item.SkuName.length > 0">规格:{{item.SkuName}}</em>
							</div>
						</span>
						<span class="C5">￥{{item.GoodsPrice}}</span>
						<div class="C6">
							{{item.Num}}
						</div>
						<span class="C8">
							<em>￥{{(item.GoodsPrice*item.Num).toFixed(2)}}</em>
						</span>
					</div>
				</div>

		</div>
		
		<div class="Count">
			<div class="Info">
				<span style="width: 350px;">
					<el-select v-if="InvoiceList.length > 0" v-model="InvoiceId" placeholder="请选择发票" size="mini" style="width: 235px;">
						<el-option  v-for="item in InvoiceList" :key="item.Id" :label="'发票:'+item.Title + (item.Type == 'person' ? ' - 个人':' - 单位') + (item.Type == 'special' ? '专票':'普票')" :value="item.Id"></el-option>
					</el-select>
					<label style="margin-left: 10px;font-size: 12px;cursor: pointer;" @click="NewInvoiceDialog = true"><i class="el-icon-circle-plus-outline"></i>新建发票</label>
				</span>
				
				<span style="width: 350px;">
					<el-input placeholder="订单备注" size="mini" v-model="OrderMessage"></el-input>
				</span>
				<div style="padding-right: 10px;">
					订单总额:
					<label>￥{{(parseFloat(CountMoney) - CouponMoney + ExpressFee).toFixed(2)}}</label>
					<i style="color: rgba(0,0,0,0.4);padding-left: 10px;">(含运费{{ExpressFee.toFixed(2)}}元)</i>
				</div>
			</div>
			<div class="Act" @click="NewOrder()">
				<i class="el-icon-s-claim"></i>下单结算
			</div>
		</div>
		
	</div>
</div>
<Footer></Footer>
	
	<el-dialog
	  title="发票添加"
	  :visible.sync="NewInvoiceDialog"
	  width="600px"
	  :before-close="NewInvoiceDialogClose">
		<NewInvoice @changeInvoiceId="EmitChangeInvoiceId"></NewInvoice>
	</el-dialog>
	
	<el-dialog
	  title="地址添加"
	  :visible.sync="NewAddressDialog"
	  width="800px"
	  :before-close="NewAddressDialogClose">
		<NewAddress @changeAddressId="EmitChangeAddressId"></NewAddress>
	</el-dialog>
	
</div>
</template>

<script>
	import {InputNumber,Checkbox,Select,Option,Dialog} from 'element-ui'
	import NewInvoice from '@/components/invoice/NewInvoice.vue'
	import NewAddress from '@/components/address/NewAddress.vue'
	export default {
	  name: 'OrderCheck',
	  props: {
	  },
	  data() {
	      return {
			  CartList:[],
			  CountMoney:0,
			  InvoiceList:[],//发票列表
			  InvoiceId:'',
			  NewInvoiceDialog:false,
			  AddressList:[],//地址列表
			  AddressId:'',
			  LockNewAddress:'',
			  NewAddressDialog:false,
			  IsShowAllList:false,
			  CartIds:[],
			  OrderMessage:'',
			  CouponList:[],//我的全部优惠券-有效期内的
			  CouponMoney:0.00,
			  ExpressFee:0.00,//快递费
	      }
	    },
	  components: {
		  "el-input-number":InputNumber,
		    "el-checkbox":Checkbox,
			'el-select':Select,
			'el-option':Option,
			'el-dialog':Dialog,
			NewInvoice,
			NewAddress
	  },
	  created() {
	  	if(this.$store.getters.getToken == ''){
	  		this.$Jump('/')
	  	}
		this.getInvoiceListList()
		this.getAddressList()
		this.getCouponList()
	  },
	  methods:{
		  CouponMoneyCount(){
			  let that = this
			  that.CouponMoney = 0.00
			  this.CartList.forEach(shopCart => {
				  if(shopCart.CouponId == ''){
					  return
				  }
				  for(var i=0;i<that.CouponList.length;i++){
					  if((that.CouponList[i].ShopId == shopCart.Id || that.CouponList[i].ShopId == 'all') && shopCart.CouponId == that.CouponList[i].Id ){
						   that.CouponMoney += parseFloat(that.CouponList[i].CuponMoney)
					  }
				  }
			  })
		  },
		  NewInvoiceDialogClose(){
			  this.InvoiceId = ''
			  this.NewInvoiceDialog = false
		  },
		  EmitChangeInvoiceId(_id){
			  this.InvoiceId = _id
			  this.NewInvoiceDialog = false
			  this.getInvoiceListList()
		  },
		  NewAddressDialogClose(){
			  this.AddressId = ''
			  this.NewAddressDialog = false
			  
		  },
		  EmitChangeAddressId(_id){

			  this.AddressId = _id
			  this.NewAddressDialog = false
			  this.LockNewAddress = _id
			  this.getAddressList()
		  },
		  SelAddr(_addr){
			  this.AddressId = _addr.Id
			  this.IsShowAllList = false
		  },
		  initUriCartIds(){ //初始化地址栏中的商品ids
		  
			  let idsStr = this.$route.params.CartIds
			  if(!idsStr || idsStr.length < 32){
				  this.$message('非法的请求路径，禁止访问')
				  return
			  }
			  
			  this.CartIds = idsStr.split(',')
			  this.GetCartList(this.CartIds)
			  
		  },
		  getCouponList(){
		  	let data = {
		  		Service:'Goods',
		  		Class: 'CouponRecive',
		  		Action: 'List',
		  		Page:1,
		  		PageSize:200,
				FromCouponChannel:'buy',
				IsUseAble:1,
		  	}
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		this.CouponList = res.Data.CouponList
		  		
		  	})
		  },
		getInvoiceListList(){
			let data = {
				Service:'Goods',
				Class: 'Invoice',
				Action: 'List',
				Page:1,
				PageSize:20,
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				if(res.Data.InvoiceList != null){
					this.InvoiceList = res.Data.InvoiceList
					//判断是否有默认的，使用第一个为默认
					if(this.InvoiceId == ''){
						for(var i=0;i<this.InvoiceList.length;i++){
							if(this.InvoiceList[i].IsDefault == 1){
								this.InvoiceId = this.InvoiceList[i].Id
								break
							}
						}
					}
				}
				
				
			})
		},
		getAddressList(){
			let data = {
				Service:'Address',
				Class: 'Address',
				Action: 'List',
				Page:1,
				PageSize:20,
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				
				if(res.Data.AddressList != null){
					this.AddressList = res.Data.AddressList
					
					if(this.LockNewAddress == ''){
						//判断是否有默认的，使用第一个为默认
						for(var i=0;i<this.AddressList.length;i++){
							if(this.AddressList[i].IsDefault == 1){
								this.AddressId = this.AddressList[i].Id
								break
							}
						}
					}else{
						this.LockNewAddress = ''
					}
				
				}
				
				this.initUriCartIds()
				
				
			})
		},
		GetCartList(_ids = []){
					  
					  this.CartList = []
					  //发起网络请求，添加购物清单
					  let data = {
					  	Service:'Goods',
					  	Class: 'ShoppingCart',
					  	Action: 'List',
					  	Page:1,
					  	PageSize:50,
						Ids:JSON.stringify(_ids),
						IsExpressFee:1,
						AddressId:this.AddressId,
					  }
					  
					  this.$post(this.$store.getters.getApiHost,data)
					  .then((res) => {
					  	
					  	if(res.ErrorId != 0){
					  		this.$message(res.Msg)
					  		return
					  	}
					  	
						if(res.Data.CartList == null){
							return
						}
					  	this.CartList = res.Data.CartList
						this.Page = res.Data.CurrentPage
						this.Total = res.Data.Total
						
						this.CartList.forEach(shopCart => {
							shopCart.CouponId = ''//强写优惠券编号
							for(var i = 0;i<shopCart.GoodsList.length;i++){
								this.CountMoney += shopCart.GoodsList[i].GoodsPrice*shopCart.GoodsList[i].Num
								this.ExpressFee += shopCart.GoodsList[i].ExpressFee
							}
						})
					
						
						this.CountMoney = this.CountMoney.toFixed(2)
					  	
					  })
		},
		NewOrder(){
					 
					 let couponIds = []
					 this.CartList.forEach(shopCart => {
						 if(shopCart.CouponId != ''){
							 let temp = {
								 ShopId:shopCart.Id,
								 CouponId:shopCart.CouponId
							 }
							 couponIds.push(temp)
						 }
					 })
					 
					  //发起网络请求，添加购物清单
					  let data = {
					  	Service:'Order',
					  	Class: 'Order',
					  	Action: 'Add',
						CartIds:JSON.stringify(this.CartIds),
						AddressId:this.AddressId,
						InvoiceId:this.InvoiceId,
						Message:this.OrderMessage,
						Coupons:JSON.stringify(couponIds)
					  }
					  
					  this.$post(this.$store.getters.getApiHost,data)
					  .then((res) => {
					  	
					  	if(res.ErrorId != 0){
					  		this.$message(res.Msg)
					  		return
					  	}
						
						//将取回的IDS构建为一个链接字符串，传入支付页
						let ids = ""
						for(var i=1;i<=res.Data.OrderIdList.length;i++){
							if(i == res.Data.OrderIdList.length){ //最后一个
								ids += res.Data.OrderIdList[i-1]
							}else{
								ids += res.Data.OrderIdList[i-1] + ','
							}
						}
						
					  	this.$Jump('/order/prepay/'+ids)
					  	
					  })
		},
		
	  }
	}
</script>

<style scoped>
	.BuyTitle{
		height: 45px;
		line-height: 45px;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		width: 100%;
		margin-top: 20px;
		font-size: 1.2rem;
		color: rgba(0,0,0,0.3);
	}
	.Address{
		width: 100%;
	}
	.Address .Title{
		display: flex;
		width: 100%;
		padding: 10px 0px;
		align-items: center;
	}
	.Address .Title h3{
		border-left: 2px solid rgba(228,0,0,1);
		display: inline-block;
		padding-left: 10px;
	}
	.Address .Title div{
		flex: 1;
		text-align: right;
	}
	.Address .Title div span{
		margin-left: 20px;
		cursor: pointer;
	}
	.Address .Title div span:hover{
		color: rgba(228,0,0,1);
	}
	.Address .List{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow: hidden;
		height: 130px;
	}
	.Address .AllList{
		height: auto;
	}
	.Address .List .One{
		width: 24%;
		height: 80px;
		border: 1px solid rgba(0,0,0,0.08);
		margin-bottom: 15px;
		border-radius: 5px;
		padding: 20px 0px;
		position: relative;
		cursor: pointer;
	}
	.Address .List .One:hover{
		border: 1px solid rgba(228,0,0,1);
	}
	.Address .List .One li{
		line-height: 20px;
		font-size: 1.2rem;
		padding: 0px 20px;
	}
	.Address .List .One p{
		padding: 0px 20px;
	}
	.Address .List .One span{
		position: absolute;
		right: 0px;
		bottom: 0px;
		border-radius: 5px 0px 5px 0px;
		background-color: rgba(228,0,0,1);
		padding: 3px 5px;
		color: #FFFFFF;
	}
	.Address .List .One ul{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Address .List .One ul i{
		margin-right: 10px;
	}
	.Address .List .Empty{
		border-color: rgba(0,0,0,0);
		cursor: default;
	}
	.Address .List .Empty:hover{
		border-color: rgba(0,0,0,0);
	}
	.OrderTitle{
		display: flex;
		width: 100%;
		padding: 10px 0px;
		align-items: center;
	}
	.OrderTitle h3{
		border-left: 2px solid rgba(228,0,0,1);
		display: inline-block;
		padding-left: 10px;
	}
	.OrderTitle div{
		flex: 1;
		text-align: right;
	}
	
	.GoodsList{
		width: 100%;
	}
	.GoodsList .Hi{
		display: flex;
		width: 100%;
		padding: 15px 0px;
		border: 1px solid rgba(0,0,0,0.05);
		border-top: 0px;
		align-items: center;
	}
	.GoodsList .Hi:hover{
		background-color: rgba(228,0,0,0.04);
	}
	.GoodsList .Title{
		background-color: rgba(0,0,0,0.05);
	}
	.GoodsList .Hi span.CartSpan{
		text-align: center;
	}
	.GoodsList .C1{
		width: 60px;
		padding-left: 10px;
	}
	.GoodsList .C2{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C2 img{
		width: 80px;
	}
	.GoodsList .C3{
		flex: 1;
		padding-left: 10px;
	}
	.GoodsList .C3 p{
		margin-top: 0px;
		padding-right: 10px;
		color: #888;
		margin-bottom: 3px;
	}
	.GoodsList .C3 p i{
		font-style: normal;
		color: rgb(228,0,0,1);
	}
	.GoodsList .C3 em{
		color: rgba(0,0,0,0.3);
	}
	.GoodsList .C4,.GoodsList .C5,.GoodsList .C6,.GoodsList .C7{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C8{
		width: 120px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.C8 em{
		display: block;
		width: 100%;
	}
	.C8 em label{
		cursor: pointer;
	}
	
	.Count{
		margin-top: 20px;
		display: flex;
		width: 100%;
	}
	.Count .Info{
		flex: 1;
		border: 1px solid rgba(0,0,0,0.04);
		border-right-width: 0px;
		display: flex;
		padding: 10px;
		align-items: center;
	}
	.Count .Info span{
		margin-left: 15px;
		cursor: pointer;
	}
	.Count .Info div{
		flex: 1;
		text-align: right;
	}
	.Count .Info div em{
		margin-left: 10px;
		margin-right: 10px;
	}
	.Count .Info div i{
		color: rgba(228,0,0,1);
		font-style: normal;
	}
	.Count .Info div label{
		font-size: 1.6rem;
		color: rgba(228,0,0,1);
	}
	.Count .Act{
		background-color: rgba(228,0,0,1);
		color: #FFFFFF;
		width: 200px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
</style>
