<template>
	<div class="Invoice">
		
		<div class="List">
			
			<div class="One">
				
				<li>
					<span>发票类型</span>
					<em>
						<el-select v-model="Invoince.Type">
							<el-option label="个人-增值税普票" :value="'person'"></el-option>
							<el-option label="单位-增值税普票" :value="'normal'"></el-option>
							<el-option label="单位-增值税专用发票" :value="'special'"></el-option>
						</el-select>
					</em>
				</li>
				
				<li>
					<span>发票抬头</span>
					<em>
						<el-input :style=" Invoince.Type == 'person' ? 'width: 200px;':'width: 400px;' " v-model="Invoince.Title" ></el-input>
					</em>
				</li>
				<template v-if="Invoince.Type != 'person'">
					<li>
						<span>发票税号</span>
						<em><el-input v-model="Invoince.DutyParagraph" style="width: 400px;"></el-input></em>
					</li>
				</template>
				
				<template v-if="Invoince.Type == 'special'">
					<li>
						<span>单位地址
						<i style="display: block;font-size: 0.9rem;color: rgba(0,0,0,0.4);">需包含联系人和电话</i>
						</span>
						<em><el-input v-model="Invoince.CompanyAddress" style="width: 400px;"></el-input>
							
						</em>
					</li>
					<li>
						<span>单位开户银行</span>
						<em><el-input  v-model="Invoince.CompanyBank"  style="width: 200px;"></el-input>
						</em>
					</li>
					<li>
						<span>单位银行账号</span>
						<em><el-input  v-model="Invoince.CompanyBankCardId" style="width: 400px;"></el-input>
						</em>
					</li>
				</template>
				
				<template v-if="Invoince.Type != 'special'">
					<li>
						<span>电子发票接收邮箱</span>
						<em>
							<el-input v-model="Invoince.Mail" style="width: 200px;"></el-input>
							<i>开具电子发票时需要</i>
						</em>
					</li>
				</template>
				
				
				<template v-if="Invoince.Id != ''">
					
					<li>
						<span>默认情况</span>
						<em>{{Invoince.IsDefaultName}}</em>
					</li>
					
					<li>
						<span>创建时间</span>
						<em>{{Invoince.CreatedAt}}</em>
					</li>	
					<li>
						<span>修改时间</span>
						<em>{{Invoince.UpdatedAt}}</em>
					</li>	
				</template>
				
				<li>
					<span></span>
					<em>
						<el-button type="primary" @click="UpdateInvoice()">提交</el-button>
					</em>
				</li>
			</div>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Checkbox} from 'element-ui'
	export default {
	  name: 'InvoiceNewComponent',
	  props: {
	  },
	  data() {
	      return {
			  Invoince:{
				  Id:'',
				  Type:'person',//发票类型 person个人 normal企业普票 special企业专票
				  Title:'',
				  DutyParagraph:'',
				  CompanyAddress:'',
				  CompanyBank:'',
				  CompanyBankCardId:'',
				  Mail:'',
				  Status:50
			  },
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox
	  },
	  created() {
	  },
	  methods:{
		UpdateInvoice(){

			let data = {
				Service:'Goods',
				Class: 'Invoice',
				Action: 'Add',
				Id:this.Invoince.Id,
				Type:this.Invoince.Type,
				Title:this.Invoince.Title,
				DutyParagraph:this.Invoince.DutyParagraph,
				CompanyAddress:this.Invoince.CompanyAddress,
				CompanyBank:this.Invoince.CompanyBank,
				CompanyBankCardId:this.Invoince.CompanyBankCardId,
				Mail:this.Invoince.Mail,
				IsDefault:2,
			}
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				this.$message(res.Msg)
				if(res.ErrorId != 0){
					return
				}
				
				this.$message(res.Msg)
				this.$emit('changeInvoiceId',res.Data.Id);
				
			})
			
		},
		
	  }
	}
</script>

<style scoped>
.Invoice{
}
.Invoice .Top{
	display: flex;
	align-items: center;
}
.Invoice .Top .Left{
	
}
.Invoice .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Invoice .List{
}
.Invoice .List .One{
}
.Invoice .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Invoice .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Invoice .List .One li  em{
	flex: 1;
}
.Invoice .List .One li  em i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 10px;
}
.Invoice .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
</style>
