<template>
	<div class="Address">
		
		<div class="List">
			
			<div class="One">
				<li>
					<span>收件人</span>
					<em>
						<el-input v-model="Address.Addressee" style="width: 200px;"></el-input>
					</em>
				</li>
				<li>
					<span>所在地区</span>
					<em>
						
						<div>
							<el-select @change="ProvinceIdChange" v-model="Address.ProvinceId" placeholder="选择省份">
								<el-option v-for="item in ProvinceList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
							</el-select>
						</div>
						<div>
							<el-select @change="CityIdChange" v-model="Address.CityId" placeholder="选择城市">
								<el-option v-for="item in CityList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
							</el-select>
						</div>
						<div>
							<el-select @change="RegionIdChange" v-model="Address.RegionId" placeholder="选择区县">
								<el-option v-for="item in RegionList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
							</el-select>
						</div>
						
						<div>
							<el-select v-model="Address.TownId" placeholder="选择乡镇街道">
								<el-option v-for="item in TownList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
							</el-select>
						</div>
						
					</em>
				</li>
				<li>
					<span>详细地址</span>
					<em><el-input v-model="Address.Detail" style="width: 600px;"></el-input></em>
				</li>	
				<li>
					<span>手机</span>
					<em><el-input v-model="Address.Mobile" style="width: 200px;"></el-input></em>
				</li>
				<li>
					<span></span>
					<em>
						<el-button type="primary" @click="UpdateAddress()">添加地址</el-button>
					</em>
				</li>
			</div>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Checkbox} from 'element-ui'
	export default {
	  name: 'NewAddress',
	  props: {
	  },
	  data() {
	      return {
			  ProvinceList:[],
			  CityList:[],
			  RegionList:[],
			  TownList:[],
			  Address:{
				  Id:'',
				  ProvinceId:'',
				  CityId:'',
				  RegionId:'',
				  TownId:'',
				  Addressee:'',//收件人姓名
				  Detail:'',//地址详情
				  Mobile:''//电话
			  }
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox
	  },
	  created() {
	  	this.GetAreaList()
	  },
	  methods:{
		  ProvinceIdChange(e){
			  this.Address.CityId = ''
			  this.Address.RegionId = ''
			  this.Address.TownId = ''
			  this.GetAreaList(e,'City')
		  },
		  CityIdChange(e){
			  this.Address.RegionId = ''
			  this.Address.TownId = ''
		  		this.GetAreaList(e,'Region')
		  },
		  RegionIdChange(e){
			  this.Address.TownId = ''
		  		this.GetAreaList(e,'Town')
		  },
		  UpdateAddress(){
		  
		  	let data = {
		  		Service:'Address',
		  		Class: 'Address',
		  		Action: 'Update',
		  		Id:this.Address.Id,
				CountryId:1,
		  		ProvinceId:this.Address.ProvinceId,
		  		CityId:this.Address.CityId,
				RegionId:this.Address.RegionId,
				TownId:this.Address.TownId,
				Addressee:this.Address.Addressee,
				Mobile:this.Address.Mobile,
				Detail:this.Address.Detail,
		  	}
		  	if(this.Address.Id == ''){
		  		data.Action = 'Add'
		  	}
		  	
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		
				this.$message({message:res.Msg,type:'success'})
				this.$emit('changeAddressId',res.Data.Id);
		  		
		  	})
		  	
		  },
		  GetAreaList(_fatherId = 1,_children = 'Province'){
			  console.log(_children)
			  let data = {
			  	Service:'Address',
			  	Class: 'Address',
			  	Action: 'AreaList',
			  	Pid:_fatherId,
				Page:1,
				PageSize:60,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
				if(_children == 'Province'){
					this.ProvinceList = res.Data.AreaList
				}else if(_children == 'City'){
					this.CityList = res.Data.AreaList
				}else if(_children == 'Region'){
					this.RegionList = res.Data.AreaList
				}else if(_children == 'Town'){
					this.TownList = res.Data.AreaList
				}
			  	

			  })
		  }
	  }
	}
</script>

<style scoped>
.Address{
	background-color: #FFFFFF;
}
.Address .Top{
	display: flex;
	align-items: center;
}
.Address .Top .Left{
	
}
.Invoice .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Address .List{
}
.Address .List .One{
}
.Address .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Address .List .One li span{
	width: 100px;
	color: rgba(0,0,0,0.3);
}
.Address .List .One li  em{
	flex: 1;
	display: flex;
}
.Address .List .One li  em div{
	margin-right: 5px;
}
.Address .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
</style>
